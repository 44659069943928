import React, { useState, useRef } from 'react';

import { Link } from 'gatsby';

import classNames from 'classnames';

import { useStaticQuery, graphql } from 'gatsby';

import ThemeContext from '../../../context/ThemeContext.js';

import useClickOutside from '../../../hooks/useClickOutside.js';

import ButtonIconOnly from '../../natura11y/button/ButtonIconOnly.js';

import Dropdown from './Dropdown.js';

import Logo from './Logo';

import { activeLinkStyles } from '../../../mdxVars.js';

import './style.scss';

const Header = (props) => {

    const { 
        navType = 'inline',
        breakpoint = 'xl',
        utilities = null 
    } = props;

    const versionDropdown = useRef();
    const themePickerRef = useRef();

    const [menuShow, setMenuShow] = useState(false);
    const [themeShow, setThemeShow] = useState(false);

    useClickOutside(versionDropdown, () => setDropdownShow(false));
    useClickOutside(themePickerRef, () => setThemeShow(false));

    const handleMenuClick = () => {
        setMenuShow(prevState => !prevState);
    };

    const handleThemeClick = () => {
        setThemeShow(prevState => !prevState);
    };

    const componentClasses = classNames(
        `primary-nav--${navType}--${breakpoint}`, 
        { 
            [`${utilities}`]: utilities
        }
    );

    const data = useStaticQuery(graphql`
        query versionQuery {
            site {
                siteMetadata {
                    version
                }
            }
        }
    `);

    const getLocation = ({ location }) => {
        if (location?.pathname?.includes('docs')) {
            return {
                style: activeLinkStyles,
                'aria-current': 'page',
            };
        }
        return null;
    };

    const version = data.site.siteMetadata.version;
    const versionTruncated = version.substring(0, version.lastIndexOf('.'));

    return (
        <>
        
            <div className='skip-links'>
                <a className='focusable-only' href='#main'>Jump to main content</a>
                <a className='focusable-only' href='#global-footer'>Jump to website footer</a>
            </div>

            <header className={componentClasses}>

                <div className='primary-nav__logo'>
                    <Link to='/' title='Home' data-logo='brand'>
                        <Logo />
                    </Link>
                </div>

                <nav className={`primary-nav__menu ${menuShow ? 'shown' : ''}`} id='main-menu' aria-label='Main Menu'>
                    <ul>
                        <li>
                            <Dropdown
                                title={versionTruncated}
                                utilities='nav--has-icons font-size-sm box-shadow-1--lg'
                            >
                                <li>
                                    <Link href='/'>
                                        <span className='icon icon-confirm'></span>
                                        <span className='nav__text'>Current: {version}</span>
                                    </Link>
                                </li>
                                <li>
                                    <a href='https://gonatura11y.com/v3/' target='_blank' rel='noopener noreferrer'>
                                        <span className='icon icon-open-new'></span>
                                        <span className='nav__text'>v3.x docs</span>
                                    </a>
                                </li>
                                <li>
                                    <a href='https://gonatura11y.com/v2/' target='_blank' rel='noopener noreferrer'>
                                        <span className='icon icon-open-new'></span>
                                        <span className='nav__text'>v2.x docs</span>
                                    </a>
                                </li>
                                <li>
                                    <a href='https://gonatura11y.com/v1/' target='_blank' rel='noopener noreferrer'>
                                        <span className='icon icon-open-new'></span>
                                        <span className='nav__text'>v1.x docs</span>
                                    </a>
                                </li>
                            </Dropdown>
                        </li>
                        <li>
                            <Link to='/docs/get-started' activeStyle={activeLinkStyles} getProps={getLocation}>
                                Docs
                            </Link>
                        </li>
                        <li>
                            <Link to='/icons' activeStyle={activeLinkStyles} partiallyActive={true}>
                                Icons
                            </Link>
                        </li>
                        <li>
                            <Link to='/examples' activeStyle={activeLinkStyles} partiallyActive={true}>
                                Examples
                            </Link>
                        </li>
                        <li className='display-none'>
                            <Link to='/leaflets' activeStyle={activeLinkStyles} partiallyActive={true}>
                                Leaflets
                            </Link>
                        </li>
                    </ul>
                </nav>

                <div className='primary-nav__toggle'>

                    <ButtonIconOnly
                        iconHandle={menuShow ? 'close' : 'menu'}
                        clickHandler={handleMenuClick}
                        ariaLabel='Menu'
                        ariaExpanded={menuShow}
                    />
                
                </div>

                <ThemeContext.Consumer>

                    {({themeType, handleOsTheme, handleDarkTheme, handleLightTheme }) => (
                        <div
                            className='primary-nav__actions theme-picker'
                            ref={themePickerRef}
                        >

                            <ButtonIconOnly
                                clickHandler={handleThemeClick}
                                ariaExpanded={themeShow}
                                iconHandle='mode-light-dark'
                                screenReaderText='Theme Picker'
                                tag='button'
                                title='Set Theme'
                            />

                            {themeShow && (
                                <div className='theme-picker__dropdown box-shadow-1'>
                                    <ul className='nav nav--has-icons nav--divider' role='menu'>
                                        <li>
                                            <button
                                                onClick={handleOsTheme}
                                                aria-expanded={themeType === 'os'}
                                            >
                                                <span className='icon icon-mode-light-dark'></span>
                                                <span className='nav__text'>OS Default</span>
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                onClick={handleDarkTheme}
                                                aria-expanded={themeType === 'dark'}
                                            >
                                                <span className='icon icon-moon'></span>
                                                <span className='nav__text'>Dark</span>
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                onClick={handleLightTheme}
                                                aria-expanded={themeType === 'light'}
                                            >
                                                <span className='icon icon-sun'></span>
                                                <span className='nav__text'>Light</span>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    )}

                </ThemeContext.Consumer>

            </header>
        
        </>
    );
};

export default Header;