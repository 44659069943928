import React from 'react';

// Will import from NPM once 4x version published
// import 'natura11y/src/scss/natura11y.scss';

import './src/scss/index.scss';
import './src/scss/theme.scss';

import Layout from './src/components/ui/Layout';

export const wrapPageElement = ({ element, props }) => {
	return <Layout {...props}>{element}</Layout>;
};

// gatsby-browser.js
export const onRouteUpdate = ({ location }) => {
  // Check if the location has a hash (anchor)
  if (location.hash) {
    const anchor = document.querySelector(location.hash);

    // Scroll to the anchor element if it exists
    if (anchor) {
      setTimeout(() => {
        anchor.scrollIntoView({ behavior: 'auto' });
      }, 0);
    }
  } else {
    // Default behavior: scroll to the top if no hash
    window.scrollTo(0, 0);
  }
};