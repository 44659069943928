import React, { createContext, useState, useEffect } from 'react';

const ThemeContext = createContext('os');

export const ThemeProvider = ({ children }) => {

	const [themeType, setThemeType] = useState('os');

	const setTheme = (theme) => {
		localStorage.setItem('preferred-theme', theme);
		setThemeType(theme);
	};

	const handleOsTheme = () => {
		setTheme('os');
	};

	const handleDarkTheme = () => {
		setTheme('dark');
	};

	const handleLightTheme = () => {
		setTheme('light');
	};

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const storedTheme = localStorage.getItem('preferred-theme') || 'os';
			setThemeType(storedTheme);
		}
	}, []);

	useEffect(() => {
		const htmlEl = document.querySelector('html');
		
		// Remove previous theme-related classes
		htmlEl.classList.remove('theme-dark', 'theme-canvas', 'theme-canvas--prefers');

		// Apply the correct theme class based on the themeType
		switch (themeType) {
			case 'dark':
			htmlEl.classList.add('theme-dark');
			break;
			case 'light':
			htmlEl.classList.add('theme-canvas');
			break;
			default:
			htmlEl.classList.add('theme-canvas--prefers');
			break;
		}
	}, [themeType]);

	const contextValue = {
		themeType,
		handleOsTheme,
		handleDarkTheme,
		handleLightTheme,
	};

	return (
		<ThemeContext.Provider value={contextValue}>
			{children}
		</ThemeContext.Provider>
	);
};

export default ThemeContext;