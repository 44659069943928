import React, { forwardRef } from 'react';

import classNames from 'classnames';

import { Link } from 'gatsby';

import Icon from '../icon/Icon';

const ButtonIconOnly = forwardRef((props, ref) => {
  
  const {
    tag = 'button',
    iconHandle = 'home',
    screenReaderText = null,
    linkUrl = '#1',
    ariaLabel = null,
    clickHandler = null,
    ariaExpanded = null,
    utilities = null, // For example, 'theme-primary'
    attributes = {} // Custom data attributes
  } = props;

  const componentClasses = classNames('button', 'button--icon-only', {
    [utilities]: utilities !== null,
  });

  const Component = {
    button: (
      <button
        ref={ref}
        className={componentClasses}
        onClick={clickHandler}
        aria-label={ariaLabel}
        aria-expanded={ariaExpanded}
        {...attributes} // Apply custom data attributes
      >
        <Icon
          screenReaderText={screenReaderText}
          iconHandle={iconHandle}
        />
      </button>
    ),
    link: (
      <Link
        className={componentClasses}
        to={linkUrl}
        aria-label={ariaLabel}
        {...attributes} // Apply custom data attributes
      >
        <Icon
          screenReaderText={screenReaderText}
          iconHandle={iconHandle}
        />
      </Link>
    ),
    a: (
      <a
        className={componentClasses}
        href={linkUrl}
        aria-label={ariaLabel}
        {...attributes} // Apply custom data attributes
      >
        <Icon
          screenReaderText={screenReaderText}
          iconHandle={iconHandle}
        />
      </a>
    ),
  };

  return Component[tag] || Component.button;
});

export default ButtonIconOnly;