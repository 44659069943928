import React, { Fragment } from 'react';

import classNames from 'classnames';

const Icon = (props) => {
    const {
        iconHandle = 'home',
        screenReaderText = null,
        utilities = ''
    } = props;

    const componentClasses = classNames(
        'icon',
        `icon-${iconHandle}`,
        utilities
    );

    return (
        <Fragment>
            <span className={componentClasses} aria-hidden='true' />
            {screenReaderText && (
                <span className='screen-reader-only'>
                    {screenReaderText}
                </span>
            )}
        </Fragment>
    );
};

export default Icon;