import React, { useState, useEffect, useRef } from 'react';

import classNames from 'classnames';

import { getFocusableElements } from '../../../utilities/focus';

const Dropdown = (props) => {

    const {
        type = 'dropdown',
        title = 'Dropdown',
        utilities = null, 
        children 
    } = props;

    const [menuShow, setMenuShow] = useState(false);
    const menuButton = useRef(null);
    const menuRef = useRef(null);

    const menuClasses = classNames(
        'nav__dropdown',
        {        
            'mega-menu--lg box-shadow-1--lg padding-4': type === 'mega',
            'shown': menuShow,
            [utilities]: utilities !== null
        }
    );
    
    const MenuContainer = type === 'mega' ? 'div' : 'ul';

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (!menuRef.current.contains(e.target) && !menuButton.current.contains(e.target)) {
                setMenuShow(false);
            }
        };

        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                setMenuShow(false);
                menuButton.current.focus();
            } else if (menuShow && (e.key === 'ArrowDown' || e.key === 'ArrowUp')) {
                e.preventDefault();
                navigateMenu(e.key);
            } else if (e.key === 'Tab' && menuShow) {
                handleTabNavigation(e);
            }
        };

        window.addEventListener('click', handleOutsideClick);
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('click', handleOutsideClick);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [menuShow]);

    const handleClick = () => {
        setMenuShow(!menuShow);
    };

    const navigateMenu = (direction) => {
        const items = getFocusableElements(menuRef.current); // Get all focusable elements inside the menu
        if (items.length === 0) return;

        const currentIndex = items.indexOf(document.activeElement);
        let nextIndex;

        if (direction === 'ArrowDown') {
            nextIndex = (currentIndex + 1) % items.length; // Loop to the top if at the bottom
        } else if (direction === 'ArrowUp') {
            nextIndex = (currentIndex - 1 + items.length) % items.length; // Loop to the bottom if at the top
        }

        items[nextIndex]?.focus(); // Focus on the next item
    };

    const handleTabNavigation = (e) => {
        const focusableItems = getFocusableElements(menuRef.current);
        const lastIndex = focusableItems.length - 1;

        if (document.activeElement === focusableItems[lastIndex] && !e.shiftKey) {
            setMenuShow(false);
            menuButton.current.closest('li').nextElementSibling?.querySelector('button, a')?.focus();
            e.preventDefault();
        }
    };

    return (
        <>
            <button
                data-toggle="dropdown"
                ref={menuButton}
                aria-expanded={menuShow}
                aria-haspopup="true"
                onClick={handleClick}
            >
                {title}
            </button>

            <MenuContainer
                ref={menuRef}
                className={menuClasses}
                aria-hidden={!menuShow}
                role="menu"
            >
                {children}
            </MenuContainer>
        </>
    );
};

export default Dropdown;