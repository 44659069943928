import React from 'react';

import Header from './header';
import Footer from './Footer';

import { ThemeProvider } from '../../context/ThemeContext';

import { LightboxProvider } from '../../context/LightboxContext';

const Layout = ({ children }) => {

	return (
		<ThemeProvider>
			<Header utilities='box-shadow-1 z-index-2500' />
			<main id='main'>
				<LightboxProvider>
					{children}
				</LightboxProvider>
			</main>
			<Footer />
		</ThemeProvider>
	);
};

export default Layout;